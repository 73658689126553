<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import axios from "axios";
import "vue3-easy-data-table/dist/style.css";
import CryptoJS from "crypto-js";

// import Select2 from 'vue3-select2-component';

export default {
    props: ['userType'],
    components: {
        Layout,
        EasyDataTable,
        // Select2
    },
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,
            searchValueTable: "",
            headers: [
                { text: "Name", value: "fullName" },
                { text: "Relation", value: "familyRelationship" },
                { text: "Total", value: "total" },
                { text: "Action", value: "staffaction" },
            ],
            arrItem: [],
            notices: [],
            completeTotal: 0
            // memberSelect: "",
            // familyMember: ['Mother', 'Father', 'Brother', "Sister"],
        };
    },
    created() {
        this.getUserDetails();
        this.getNotice();
    },
    activated() {
        this.getUserDetails();
        this.getNotice();
    },

    methods: {

        getUserDetails() {
            axios.post(this.app_URL + 'api/getUser', { user_id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) })
                .then(res => {
                    this.arrItem = res.data.data;
                    this.completeTotal = res.data.completeTotal
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        getNotice() {
            axios.post(this.app_URL + "api/getNotice", { user_id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) }).then(res => {
                this.notices = res.data;
            })
        },
        completeNoticeApplicant(id) {
            axios.post(this.app_URL + "api/completeNoticeApplicant", { id: id }).then(() => {
                this.$store.state.isAccepted = true
                this.$store.state.notificationData = 'The notice has been completed successfully'
                setTimeout(() => {
                    this.$store.state.isAccepted = false
                    this.$store.state.notificationData = null
                }, 2500)
                this.getNotice();
            })
        },
        editApplication(id) {
            console.log(id)
            axios.post(this.app_URL + "api/stepCheck", { disclosure_id: id }).then(res => {
                localStorage.setItem('currentStep', res.data);
                this.$router.push({ path: "/edit_Application/" + this.encode(id) })
            })
            // localStorage.removeItem('currentStep');
        },
        forFamilyMember() {
            localStorage.setItem('currentStep', 0);
            this.$store.state.forFamily = true;
            this.$router.push({ path: "/citizenship_application" });

        },
        encode(id) {
            return encodeURIComponent(
                CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
            );
        }
    },
};
</script>

<template>
    <Layout>
        <main class="py-4">
            <div v-if="notices.length > 0">
                <div class="tablwdataScroll mb-5">
                    <div class="row mb-1 align-items-center" v-for="(notice, index) in notices" :key="index">
                        <div class="col">
                            <div class="card">
                                <div class="card-body p-3 errorFields">
                                    <div class="row align-items-center">
                                        <div class="col-6">
                                            <div class="d-flex pb-2 align-items-center">
                                                <i class="mdi mdi-alert-circle-outline text-theme fs-4 me-2"></i>
                                                <router-link :to="`edit_application/${encode(notice.form_id)}`"
                                                    @click="$store.state.currentStep = notice.step_id">
                                                    <p class="font-size-14 m-0 text-theme">{{ notice.notice }}.</p>
                                                </router-link>
                                            </div>

                                            <i class="mdi mdi-calendar-clock me-2"></i>
                                            <span class="bg-theme-soft squareListTask">
                                                {{ new Date(notice.created_at).getDate() }} {{ new
                                                    Date(notice.created_at).toLocaleString('en-US', {
                                                        month: 'short', year:
                                                            'numeric'
                                                    }) }}
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-sm btn-secondary"
                                                @click="completeNoticeApplicant(notice.id)" v-if="notice.status == 0">
                                                Incomplete
                                            </button>
                                            <button class="btn btn-sm btn-success" v-if="notice.status == 1">
                                                Complete
                                            </button>
                                        </div>
                                        <div class="col-3 text-end">
                                            <h2 class="font-size-16 m-0">{{ notice.user.firstName }} {{ notice.user.surName
                                            }}</h2>
                                        </div>
                                    </div>

                                    <!-- <a href="#" class="btn btn-theme-dark">Edit My response</a> -->
                                </div>
                            </div>
                            <!-- <div class="card">
                            <div class="card-body errorFields">
                                <div class="d-flex align-items-center pb-3">
                                    <i class="mdi mdi-alert-circle-outline text-theme fs-4 me-2"></i>
                                    <h2 class="font-size-16 m-0">valid state Please select.</h2>
                                </div>
                                <a href="#" class="btn btn-theme-dark">Edit My response</a>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-center">Not Data Yet</p>
            </div>
            <div class="row mb-3 align-items-center">
                <div class="col">
                    <h2 class="font-size-22 text-theme-dark mb-0">Form Listing</h2>
                </div>
                <div class="col-auto" v-if="completeTotal > 0">
                    <!-- <img src="../../../assets/images/citizenship/user.png" width="200"> -->
                    <router-link to="/payment"><b-button class="btn-md btn-theme " title="View Application">
                            Proceed To Payment ${{ completeTotal }}
                        </b-button></router-link>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="row gx-2 mb-3 align-items-center">
                                <div class="col-md-4 col-lg-6 mt-md-0 mt-3">
                                    <div class="groupSearchTop">
                                        <input type="text" v-model="searchValueTable" placeholder="Search..."
                                            class="customInputForm form-control" />
                                    </div>
                                </div>
                                <div class="col-md-auto col-lg-6 text-end mt-md-0 mt-3">
                                    <!-- <router-link :disabled="arrItem.find(arr => arr.formStatus == 'progress')" class="btn btn-secondary btn-sm btn-theme-dark p-2"
                                        @click="forFamilyMember" to="/citizenship_application" role="button">+ Add
                                        Member</router-link> -->
                                    <button
                                        :disabled="this.$store.state.authenticatedUser.formStatus != 'progress' ? false : true"
                                        class="btn btn-secondary btn-sm btn-theme-dark p-2" @click="forFamilyMember"
                                        role="button" title="Please complete the form">+ Add
                                        Member</button>
                                </div>
                            </div>
                            <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
                                theme-color="#df6a0d" :rows-per-page="10" buttons-pagination show-index
                                table-class-name="table-custom-style">
                                <template #item-fullName="{ firstName, surName }">
                                    <span class="fw-medium">{{ firstName }} {{ surName }} </span>
                                </template>
                                <template #item-total="{ formStatus, total, paymentStatus }">
                                    <span v-if="formStatus == 'progress'">Incomplete</span>
                                    <span v-else-if="paymentStatus != 'pending'">Completed</span>
                                    <span v-else>${{ total }}</span>
                                </template>
                                <template #item-staffaction="{ id }">
                                    <div class="d-flex button-row">
                                        <b-button class="btn-sm btn-theme-dark" title="View Application"
                                            @click="editApplication(id)">
                                            <i class="mdi mdi-square-edit-outline"></i> Edit
                                        </b-button>
                                    </div>
                                </template>
                            </EasyDataTable>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    </Layout>
</template>